import { FormEvent, useState } from "react";
import { logEvent } from "../shared/analytics";
import {
  ENDPOINTS,
  SSO_TIMEOUT_ERROR,
  SELF_SERVICE_SUBHEADINGS,
} from "../shared/constants";
import { DataFetchStatus, StatusData, UserData } from "../shared/types";
import SelfServiceError from "./SelfServiceError";
import { getHeaders } from "../shared/functions/getHeaders";
import { useTranslation, Trans } from "react-i18next";

interface Props {
  userData: UserData;
  statusData: StatusData;
  includeBoxInfo: boolean;
}

function Request1099G({ userData, statusData, includeBoxInfo }: Props) {
  const { t } = useTranslation();
  const [fetchStatus, setFetchStatus] = useState(DataFetchStatus.Idle);
  const fetchStatusIsSuccessOrLoading =
    fetchStatus === DataFetchStatus.Success ||
    fetchStatus === DataFetchStatus.Loading;

  const initialEmailState = userData.email;
  const [email, setEmail] = useState(initialEmailState);

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setFetchStatus(DataFetchStatus.Loading);

    const formData = new FormData(e.target as HTMLFormElement);
    const data = Object.fromEntries(formData.entries());
    const endpoint = ENDPOINTS.request1099g;
    const headers = getHeaders(userData);

    fetch(endpoint, {
      method: "POST",
      headers: { ...headers },
      body: JSON.stringify({ email: data.email }),
    })
      .then((response) => {
        if (response.status === 403) {
          throw new Error(SSO_TIMEOUT_ERROR);
        }

        return response.json();
      })
      .then(({ status }) => {
        switch (status) {
          case "Success":
            setFetchStatus(DataFetchStatus.Success);
            logEvent("Request 1099-G success");
            break;
          default:
            setFetchStatus(DataFetchStatus.ErrorUnknown);
            logEvent("Request 1099-G error", "Unknown");
            break;
        }
      })
      .catch((error) => {
        if (error.message === SSO_TIMEOUT_ERROR) {
          setFetchStatus(DataFetchStatus.ErrorSessionTimeout);
          logEvent("Request 1099-G error", "SSO session timeout");
        } else {
          setFetchStatus(DataFetchStatus.ErrorUnknown);
          logEvent("Request 1099-G error", "Unknown");
        }
      });

    return false;
  }

  return (
    <article>
      <div className="margin-bottom-2 font-sans-xs display-block mobile-lg:display-none">
        {SELF_SERVICE_SUBHEADINGS.REQUEST_1099G}
      </div>

      <div className="option-row">
        <div>
          <p className="margin-y-0">
            {includeBoxInfo ? (
              <Trans i18nKey="selfService.request1099.viewBoxes">
                <strong>placeholder_child_0</strong>
                placeholder_child_1
                <strong>placeholder_child_2</strong>
                placeholder_child_3
              </Trans>
            ) : (
              <>{t("selfService.request1099.getEmailedCopy")}</>
            )}
          </p>
        </div>
      </div>
      <>
        <div className="usa-alert usa-alert--info usa-alert--slim override-fit-content">
          <div className="usa-alert__body">
            <p className="usa-alert__text">
              <Trans i18nKey="selfService.request1099.for2021or2020">
                placeholder_child_0
                <a
                  className="usa-link usa-link--external blue-override"
                  href="https://uiclaim.dol.state.nj.us/njsuccess/html/web1099Home.htm"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => logEvent("Clicked previous 1099-G link")}
                >
                  placeholder_child_1
                </a>
                placeholder_child_2
              </Trans>
            </p>
          </div>
        </div>
        {includeBoxInfo && (
          <div className="display-flex margin-top-3 gap-1 flex-wrap">
            <div className="tax-box flex-fill">
              <div>
                <Trans i18nKey="selfService.request1099.box1">
                  <b>placeholder_child_0</b>
                  placeholder_child_1
                </Trans>
              </div>
              <div className="font-sans-lg text-bold">
                $ {statusData.taxYearCompensation ?? "N/A"}
              </div>
            </div>
            <div className="tax-box flex-fill">
              <div>
                <Trans i18nKey="selfService.request1099.box1">
                  <b>placeholder_child_0</b>
                  placeholder_child_1
                </Trans>
              </div>
              <div className="font-sans-lg text-bold">
                $ {statusData.taxYearWithheld ?? "N/A"}
              </div>
            </div>
          </div>
        )}
        <form
          className="usa-form margin-top-3 maxw-none"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <fieldset className="usa-fieldset">
            <legend className="text-bold">
              {t("selfService.request1099.pleaseEnter")}
            </legend>
            <div className="maxw-mobile-lg">
              {fetchStatus === DataFetchStatus.Success && (
                <div className="usa-alert usa-alert--success usa-alert--slim">
                  <div className="usa-alert__body">
                    <p className="usa-alert__text">
                      {t("selfService.request1099.success")}
                    </p>
                  </div>
                </div>
              )}

              {(fetchStatus === DataFetchStatus.ErrorSessionTimeout ||
                fetchStatus === DataFetchStatus.ErrorUnknown) && (
                <div className="margin-top-2">
                  <SelfServiceError fetchStatus={fetchStatus} />
                </div>
              )}

              <label className="usa-label" htmlFor="1099Email">
                {t("status.email")}
              </label>
              <input
                className="usa-input margin-bottom-2 mobile-lg:margin-bottom-0"
                id="1099Email"
                name="email"
                required
                type="email"
                disabled={fetchStatusIsSuccessOrLoading}
                defaultValue={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              <input
                type="submit"
                className="usa-button usa-button--primary"
                value={t("selfService.submit")}
                disabled={fetchStatusIsSuccessOrLoading || email.length === 0}
              />
            </div>
          </fieldset>
        </form>
      </>
      {fetchStatus === DataFetchStatus.Loading && (
        <div className="loader margin-top-4"></div>
      )}
    </article>
  );
}

export default Request1099G;
