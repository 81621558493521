import RequestOverpayment from "./self_service/RequestOverpayment";
import ResetPin from "./self_service/ResetPin";
import { UserData, StatusData } from "./shared/types";
import Request1099G from "./self_service/Request1099G";
import dolLogo from "./assets/img/dol_logo.png";
import { logEvent } from "./shared/analytics";
import { SELF_SERVICE_OPTIONS, NJ_UNEMPLOYMENT_URL } from "./shared/constants";
import EAdjCaseAlert from "EAdjCaseAlert";
import EAdjErrorAlert from "EAdjErrorAlert";
import { sortBySeverity } from "shared/eAdjUtils";
import SsoHeader from "SsoHeader";
import { Trans, useTranslation } from "react-i18next";
import ExternalLinkButton from "ExternalLinkButton";
import SsoVerificationAlert from "verification/SsoVerificationAlert";
import Accordion from "./Accordion";
import { parseDate } from "shared/dateUtils";

interface Props {
  userData: UserData | undefined;
  statusData: StatusData | undefined;
}

const claimTypeI18nKeys = {
  "Unemployment Insurance": "UI",
  "Pandemic Unemployment Assistance": "PUA",
  "Extended Unemployment Benefits": "EUB",
  "Pandemic Extended Unemployment Compensation": "PEUC",
  "Workforce Development": "WD",
  "Trade Readjustment Allowance": "TRA",
  "Additional Benefits During Training": "ABDT",
  "Disability During Unemployment": "DDU",
};

export type claimType = keyof typeof claimTypeI18nKeys;

function Status({ userData, statusData }: Props) {
  const { t, i18n } = useTranslation();

  const OUTBOUND_SELF_SERVICE_OPTIONS: Array<{
    title: string;
    subtitle: string;
    buttonText: string;
    linkUrl: string;
    key: string;
  }> = [
    {
      title: t("selfService.manageClaim.title"),
      subtitle: t("selfService.manageClaim.subtitle"),
      buttonText: t("selfService.manageClaim.button"),
      linkUrl: NJ_UNEMPLOYMENT_URL,
      key: "manageClaim",
    },
    {
      title: t("selfService.scheduleAppt.title"),
      subtitle: t("selfService.scheduleAppt.subtitle"),
      buttonText: t("selfService.scheduleAppt.button"),
      linkUrl: "https://telegov.njportal.com/njdolui/AppointmentWizard",
      key: "scheduleAppointment",
    },
  ];

  if (statusData == null || userData == null) return null;

  const eAdjCaseSorted = [...(statusData.eAdjudication?.cases ?? [])].sort(
    sortBySeverity
  );

  const claimTypeSplit = (statusData.claimType ?? "").split(" (");
  const programName = claimTypeSplit[0];
  const programKey = `status.claimType.${
    claimTypeI18nKeys[programName as claimType]
  }`;

  // Remove extra parenthetical content returned by API Pending status

  const translateClaimStatus = (statusData: StatusData) => {
    const claimStatus = statusData.claimStatus ?? "";

    if (claimStatus.startsWith("Pending")) {
      return t("status.pending");
    } else if (claimStatus.startsWith("Filed")) {
      return t("status.filed");
    } else {
      return t("error");
    }
  };

  const translatedClaimStatus = translateClaimStatus(statusData);

  const accordionSelfServiceOptions: Array<{
    title: string;
    subtitle: string;
    content: JSX.Element;
    id: string;
  }> = [
    {
      title: t("selfService.resetPIN.title"),
      subtitle: t("selfService.resetPIN.subtitle"),
      content: <ResetPin userData={userData} />,
      id: SELF_SERVICE_OPTIONS.RESET_PIN,
    },
    {
      title: t("selfService.request1099.title"),
      subtitle: t("selfService.request1099.subtitle"),
      content: (
        <Request1099G
          userData={userData}
          statusData={statusData}
          includeBoxInfo={(statusData.selfServiceIds ?? []).includes(
            SELF_SERVICE_OPTIONS.VIEW_1099G
          )}
        />
      ),
      id: SELF_SERVICE_OPTIONS.REQUEST_1099G,
    },
    {
      title: t("selfService.requestWaiver.title"),
      subtitle: t("selfService.requestWaiver.subtitle"),
      content: <RequestOverpayment userData={userData} />,
      id: SELF_SERVICE_OPTIONS.REQUEST_OVERPAYMENT,
    },
  ];

  return (
    <main className="main-section usa-prose margin-bottom-9 margin-top-3">
      <img src={dolLogo} alt={t("dolLogo")} height={60} width={60} />

      <SsoHeader userData={userData} statusData={statusData} />

      <h1 className="title">{t("status.title")}</h1>
      <p className="text-semibold">{t("status.updated")}</p>

      <SsoVerificationAlert
        verificationInformation={statusData.idVerification}
        userEmail={(userData as UserData).email ?? ""}
      />

      <div className="usa-alert usa-alert--info usa-alert--no-icon margin-top-2">
        <div className="usa-alert__body">
          <h2 className="usa-alert__heading">{translatedClaimStatus}</h2>
          {statusData.claimStatus?.startsWith("Pending") && (
            <p className="usa-alert__text">{t("status.pendingExplanation")}</p>
          )}
          {statusData.dateOfClaim != null &&
            statusData.dateOfClaim.length > 0 && (
              <p className="usa-alert__text">
                {t("status.claimDate")}{" "}
                <strong>
                  {parseDate(statusData.dateOfClaim, i18n.language)}
                </strong>
              </p>
            )}
          {programName != null && programName.length > 0 && (
            <p className="usa-alert__text">
              {t("status.program")}{" "}
              <Trans i18nKey={programKey}>
                <strong>placeholder_child_0</strong>
                placeholder_child_1
              </Trans>
            </p>
          )}
        </div>
      </div>

      {statusData.eAdjudication?.fetchStatus === "Success" ? (
        eAdjCaseSorted.map((eAdjCase) => (
          <EAdjCaseAlert key={eAdjCase.CaseId} eAdjCase={eAdjCase} />
        ))
      ) : (
        <EAdjErrorAlert />
      )}

      <hr className="section-divider margin-y-4" />

      <section>
        <h2 className="section-title">{t("selfService.heading")}</h2>

        {OUTBOUND_SELF_SERVICE_OPTIONS.map((option) => (
          <div key={option.key}>
            <article className="option-row">
              <div>
                <h3 className="margin-top-0 margin-bottom-05">
                  {option.title}
                </h3>
                <p className="margin-y-0 font-sans-2xs">{option.subtitle}</p>
              </div>
              <ExternalLinkButton
                buttonType="outline"
                label={option.buttonText}
                linkUrl={option.linkUrl}
                onClick={() =>
                  logEvent("Clicked self-service option", option.key)
                }
                extraClassNames="flex-shrink-0 self-service-button"
              />
            </article>

            <hr className="option-divider margin-y-3" />
          </div>
        ))}

        {statusData.selfServiceIds != null && (
          <Accordion
            items={accordionSelfServiceOptions.filter((option) =>
              statusData.selfServiceIds!.includes(option.id)
            )}
            analyticsAction="Clicked self-service option"
            extraClassNames="usa-accordion--multiselectable usa-accordion--bordered margin-top-3 display-flex flex-column gap-1"
            extraButtonClassNames="padding-205"
          />
        )}
      </section>
    </main>
  );
}

export default Status;
