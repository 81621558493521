import { FormEvent, useState } from "react";
import { logEvent } from "../shared/analytics";
import { ENDPOINTS, SSO_TIMEOUT_ERROR } from "../shared/constants";
import { DataFetchStatus, UserData } from "../shared/types";
import SelfServiceError from "./SelfServiceError";
import { getHeaders } from "../shared/functions/getHeaders";
import { useTranslation } from "react-i18next";

interface Props {
  userData: UserData;
}

interface PinData {
  claimantId: string;
  claimantPin: string;
  email: string;
}

function ResetPin({ userData }: Props) {
  const [fetchStatus, setFetchStatus] = useState(DataFetchStatus.Idle);
  const fetchStatusIsSuccessOrLoading =
    fetchStatus === DataFetchStatus.Success ||
    fetchStatus === DataFetchStatus.Loading;

  const initialFormDataState = {
    claimantId: "",
    claimantPin: "",
    email: userData.email,
  };

  const [formData, setFormData] = useState<PinData>(initialFormDataState);

  const { t } = useTranslation();

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setFetchStatus(DataFetchStatus.Loading);

    const endpoint = ENDPOINTS.pin;
    const headers = getHeaders(userData);

    fetch(endpoint, {
      method: "POST",
      headers: { ...headers },
      body: JSON.stringify({
        claimantId: formData.claimantId,
        claimantPin: formData.claimantPin,
        email: formData.email,
      }),
    })
      .then((response) => {
        if (response.status === 403) {
          throw new Error(SSO_TIMEOUT_ERROR);
        }

        return response.json();
      })
      .then(({ status }) => {
        switch (status) {
          case "Success":
            setFetchStatus(DataFetchStatus.Success);
            logEvent("Reset PIN success");
            break;
          case "Error - Wrong Claimant ID":
            setFetchStatus(DataFetchStatus.ErrorIncorrectInfo);
            logEvent("Reset PIN error", "Incorrect info");
            break;
          default:
            setFetchStatus(DataFetchStatus.ErrorUnknown);
            logEvent("Reset PIN error", "Unknown");
            break;
        }
      })
      .catch((error) => {
        if (error.message === SSO_TIMEOUT_ERROR) {
          setFetchStatus(DataFetchStatus.ErrorSessionTimeout);
          logEvent("Reset PIN error", "SSO session timeout");
        } else {
          setFetchStatus(DataFetchStatus.ErrorUnknown);
          logEvent("Reset PIN error", "Unknown");
        }
      });

    return false;
  }

  return (
    <article>
      <div className="margin-bottom-2 font-sans-xs display-block mobile-lg:display-none">
        {t("selfService.resetPIN.subtitle")}
      </div>

      <form
        className="usa-form maxw-none"
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <fieldset className="usa-fieldset">
          <legend className="text-bold">
            {t("selfService.resetPIN.pleaseEnter")}
          </legend>
          <div className="maxw-mobile-lg">
            {fetchStatus === DataFetchStatus.Success && (
              <div className="usa-alert usa-alert--success usa-alert--slim">
                <div className="usa-alert__body">
                  <p className="usa-alert__text">
                    {t("selfService.resetPIN.success")}
                  </p>
                </div>
              </div>
            )}

            {(fetchStatus === DataFetchStatus.ErrorSessionTimeout ||
              fetchStatus === DataFetchStatus.ErrorUnknown ||
              fetchStatus === DataFetchStatus.ErrorIncorrectInfo) && (
              <div className="margin-top-2">
                <SelfServiceError fetchStatus={fetchStatus} />
              </div>
            )}

            {fetchStatus !== DataFetchStatus.Success && (
              <div className="usa-alert usa-alert--info usa-alert--slim margin-y-2">
                <div className="usa-alert__body">
                  <p className="usa-alert__text">
                    {t("selfService.resetPIN.notice24hrs")}
                  </p>
                </div>
              </div>
            )}

            <div className="padding-x-0">
              <div className="grid-row grid-gap">
                <div className="tablet:grid-col-6">
                  <label className="usa-label" htmlFor="claimantId">
                    {t("selfService.resetPIN.claimantID")}{" "}
                    <span className="usa-hint--required">*</span>
                  </label>
                  <span className="usa-hint" id="claimantIdHint">
                    {t("selfService.resetPIN.digits", { number: "9" })}
                  </span>
                  <input
                    className="usa-input margin-bottom-1"
                    id="claimantId"
                    name="claimantId"
                    pattern="[0-9]*"
                    minLength={9}
                    maxLength={9}
                    required
                    aria-describedby="claimantIdHint"
                    inputMode="numeric"
                    autoComplete="off"
                    disabled={fetchStatusIsSuccessOrLoading}
                    defaultValue={
                      fetchStatus === DataFetchStatus.Success &&
                      formData != null
                        ? formData.claimantId
                        : undefined
                    }
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        claimantId: event.target.value,
                      })
                    }
                  />
                  <span className="usa-hint">
                    {t("selfService.resetPIN.claimantIDExplanation")}
                  </span>
                </div>
                <div className="tablet:grid-col-6">
                  <label className="usa-label" htmlFor="pin">
                    {t("selfService.resetPIN.newPIN")}{" "}
                    <span className="usa-hint--required">*</span>
                  </label>
                  <span className="usa-hint" id="pinHint">
                    {t("selfService.resetPIN.digits", { number: "4" })}
                  </span>
                  <input
                    className="usa-input"
                    id="pin"
                    name="pin"
                    pattern="[0-9]*"
                    minLength={4}
                    maxLength={4}
                    required
                    aria-describedby="pinHint"
                    inputMode="numeric"
                    autoComplete="off"
                    disabled={fetchStatusIsSuccessOrLoading}
                    defaultValue={
                      fetchStatus === DataFetchStatus.Success &&
                      formData != null
                        ? formData.claimantPin
                        : undefined
                    }
                    onChange={(event) =>
                      setFormData({
                        ...formData,
                        claimantPin: event.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>

            <label className="usa-label" htmlFor="pinEmail">
              {t("status.email")} <span className="usa-hint--required">*</span>
            </label>
            <span className="usa-hint" id="pinEmailHint">
              {t("selfService.resetPIN.emailExplanation")}
            </span>
            <input
              className="usa-input margin-bottom-2 mobile-lg:margin-bottom-0"
              id="pinEmail"
              name="email"
              required
              type="email"
              aria-describedby="pinEmailHint"
              disabled={fetchStatusIsSuccessOrLoading}
              defaultValue={formData?.email}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  email: event.target.value,
                })
              }
            />
            <input
              type="submit"
              className="usa-button usa-button--primary"
              value={t("selfService.submit")}
              disabled={
                fetchStatusIsSuccessOrLoading ||
                formData.claimantId.length === 0 ||
                formData.claimantPin.length === 0 ||
                formData.email.length === 0
              }
            />
          </div>
        </fieldset>
      </form>
      {fetchStatus === DataFetchStatus.Loading && (
        <div className="loader margin-top-4"></div>
      )}
    </article>
  );
}

export default ResetPin;
