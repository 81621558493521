import { ENDPOINTS, SSO_AUTHORIZER_ERROR } from "../constants";
import { StatusData } from "../types";
import { getReducedBrowserCookies } from "./cookieUtils";

export async function getStatusSSO(cookie: string): Promise<StatusData> {
  const response = await fetch(ENDPOINTS.status, {
    method: "POST",
    headers: {
      "sso-token": cookie,
      "browser-cookies": getReducedBrowserCookies(),
    },
  });
  if (response.status === 403) {
    throw new Error(SSO_AUTHORIZER_ERROR);
  }
  return await response.json();
}
