import { useEffect, useRef, useState } from "react";
import { logEvent, logGA4Event } from "./shared/analytics";
import {
  DataFetchStatus,
  Page,
  SsoLoginStatus,
  StatusData,
  UserData,
} from "./shared/types";
import dolLogo from "./assets/img/dol_logo.png";
import { getStatusSSO } from "./shared/functions/getStatus";
import { getCookie } from "./shared/functions/cookieUtils";
import {
  LOGIN_URL,
  CREATE_ACCOUNT_URL,
  SESSION_COOKIE_NAME,
} from "./shared/constants";
import { useTranslation, Trans } from "react-i18next";
import ExternalLinkButton from "ExternalLinkButton";

let ssoCheckHasRun = false;

interface Props {
  setPage: React.Dispatch<React.SetStateAction<Page>>;
  setUserData: React.Dispatch<React.SetStateAction<UserData | undefined>>;
  setStatusData: React.Dispatch<React.SetStateAction<StatusData | undefined>>;
  ssoLoginStatus: SsoLoginStatus;
  setSsoLoginStatus: React.Dispatch<React.SetStateAction<SsoLoginStatus>>;
}

function Login({
  setPage,
  setUserData,
  setStatusData,
  ssoLoginStatus,
  setSsoLoginStatus,
}: Props) {
  const [dataFetchStatus, setDataFetchStatus] = useState(DataFetchStatus.Idle);
  const userCanceledSso = useRef(false);

  /*
   * Look for a session cookie, check if ForgeRock is logged in and try to fetch the claim.
   * Utilize a boolean to ensure it runs only once in development environments.
   */
  useEffect(() => {
    if (!ssoCheckHasRun) {
      ssoCheckHasRun = true;
      const cookie = getCookie(SESSION_COOKIE_NAME);
      if (cookie == null) {
        setSsoLoginStatus(SsoLoginStatus.NoCookie);
        return;
      }

      getStatusSSO(cookie)
        .then(
          ({
            statusCode,
            status,
            claimStatus,
            claimType,
            dateOfClaim,
            selfServiceIds,
            taxYearCompensation,
            taxYearWithheld,
            email,
            firstName,
            lastName,
            idVerification,
            eAdjudication,
          }) => {
            // If user clicked to cancel the SSO-login, just leave the rest alone (giveUpOnSsoLogin should remain given-up)
            // NOTE: A ref is used because state can become stale (updates are not respected) inside a useEffect
            if (userCanceledSso.current) {
              return;
            }

            if (statusCode != null) {
              setSsoLoginStatus(SsoLoginStatus.Error);
              logEvent("Login error - ForgeRock", `Status code ${statusCode}`);
            } else if (status === "Success") {
              setSsoLoginStatus(SsoLoginStatus.Success);
              setDataFetchStatus(DataFetchStatus.Idle);
              setPage(Page.Status);
              setUserData({
                email: email ?? "No email found",
                firstName,
                lastName,
              });
              setStatusData({
                status,
                claimStatus,
                claimType,
                dateOfClaim,
                selfServiceIds,
                taxYearCompensation,
                taxYearWithheld,
                idVerification,
                eAdjudication,
              });

              // Needs to be 100 characters or less for GA4
              const logLabel = {
                status: claimStatus?.includes("Pending")
                  ? "Pending"
                  : claimStatus,
                date: dateOfClaim,
                ...(idVerification != null && {
                  idMe:
                    idVerification.fetchStatus === "Error"
                      ? "Error"
                      : idVerification.isVerified,
                }),
              };

              let caseStatuses = undefined;
              let caseIssues = undefined;
              let caseSources = undefined;
              if (eAdjudication != null) {
                if (eAdjudication.fetchStatus === "Error") {
                  caseStatuses = "Error";
                  caseIssues = "Error";
                  caseSources = "Error";
                } else if (
                  eAdjudication.fetchStatus === "Success" &&
                  eAdjudication.cases != null
                ) {
                  caseStatuses = eAdjudication.cases
                    .map((eAdjCase) => eAdjCase.Status)
                    .join(";");
                  caseIssues = eAdjudication.cases
                    .map((eAdjCase) => eAdjCase.IssueCode)
                    .join("|");
                  caseSources = eAdjudication.cases
                    .map((eAdjCase) => eAdjCase.Source)
                    .join(";");
                }
              }

              logGA4Event("Login success - ForgeRock", {
                event_category: "UI Claim Status",
                event_label: JSON.stringify(logLabel),
                ...(caseStatuses != null && {
                  object_status: caseStatuses,
                }),
                ...(caseIssues != null && {
                  object_type: caseIssues,
                }),
                ...(caseSources != null && {
                  object_details: caseSources,
                }),
              });
            } else if (status === "Error - Login failed") {
              setSsoLoginStatus(SsoLoginStatus.AccountHasNoClaim);
              setStatusData({
                status,
                claimStatus,
                claimType,
                dateOfClaim,
                selfServiceIds,
                taxYearCompensation,
                taxYearWithheld,
                idVerification,
              });
              setUserData({
                email: email ?? "No email found",
                firstName,
                lastName,
              });

              setPage(Page.NoClaim);
              logEvent("Login error - ForgeRock", "Incorrect info");
            } else {
              setSsoLoginStatus(SsoLoginStatus.Error);
              logEvent("Login error - ForgeRock", `API message: ${status}`);
            }
          }
        )
        .catch((error) => {
          setSsoLoginStatus(SsoLoginStatus.Error);
          logEvent(
            "Login error - ForgeRock",
            `Error message: ${error.message}`
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function giveUpOnSsoLogin() {
    setSsoLoginStatus(SsoLoginStatus.Error);
    userCanceledSso.current = true;
    logEvent("Login attempt - ForgeRock", "Canceled auth");
  }

  const { t } = useTranslation();

  return (
    <main className="main-section usa-prose margin-y-9">
      <img src={dolLogo} alt={t("dolLogo")} height={60} width={60} />
      <h1 className="title">
        {t("unemploymentInsurance")}
        <br />
        {t("claimStatus")}
      </h1>
      <section>
        {ssoLoginStatus === SsoLoginStatus.Pending && (
          <>
            <p className="padding-top-205">{t("loading.pleaseWait")}</p>
            <p>
              <Trans i18nKey="loading.ifThisTakesLonger">
                placeholder_child_0
                <button
                  className="usa-button usa-button--unstyled blue-override"
                  onClick={giveUpOnSsoLogin}
                  data-testid="giveUpOnSsoLink"
                >
                  placeholder_child_1
                </button>
                placeholder_child_2
              </Trans>
            </p>
            <div className="loader margin-top-4 margin-bottom-6" />
          </>
        )}

        {(ssoLoginStatus === SsoLoginStatus.NoCookie ||
          ssoLoginStatus === SsoLoginStatus.Error ||
          ssoLoginStatus === SsoLoginStatus.FeatureFlagDisabled) && (
          <>
            <div className="display-flex flex-column flex-align-stretch margin-top-5">
              <ExternalLinkButton
                buttonType="primary"
                label={t("emailLoginButton")}
                linkUrl={LOGIN_URL}
                onClick={() => logEvent("Clicked ForgeRock login link")}
                extraClassNames="login-button"
                shouldOpenNewTab={false}
              />
            </div>

            <div className="margin-top-3">
              <p>
                <Trans i18nKey="dontHaveAccount">
                  placeholder_child_0
                  <a
                    href={CREATE_ACCOUNT_URL}
                    className="usa-link usa-link--external blue-override"
                    onClick={() =>
                      logEvent("Clicked ForgeRock registration link")
                    }
                  >
                    placeholder_child_1
                  </a>
                </Trans>
              </p>
            </div>

            {dataFetchStatus === DataFetchStatus.ErrorUnknown && (
              <div className="usa-alert usa-alert--error usa-alert--slim">
                <div className="usa-alert__body">
                  <p className="usa-alert__text">{t("somethingWentWrong")}</p>
                </div>
              </div>
            )}
            {dataFetchStatus === DataFetchStatus.ErrorIncorrectInfo && (
              <div className="usa-alert usa-alert--error usa-alert--slim">
                <div className="usa-alert__body">
                  <p className="usa-alert__text">{t("ssnLogin.matchError")}</p>
                </div>
              </div>
            )}

            {dataFetchStatus === DataFetchStatus.Loading && (
              <div className="loader margin-top-4" />
            )}
          </>
        )}
      </section>
      <section className="margin-top-3">
        <div className="usa-alert usa-alert--info margin-top-5 margin-bottom-9">
          <div className="usa-alert__body">
            <h2 className="usa-alert__heading">
              {t("healthResources.heading")}
            </h2>
            <p className="usa-alert__text margin-top-2">
              <Trans i18nKey="healthResources.ifYouNeedHealthInsurance">
                placeholder_child_0
                <a
                  href="http://www.njfamilycare.org/default.aspx"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="usa-link usa-link--external blue-override"
                >
                  placeholder_child_1
                </a>
                placeholder_child_2
              </Trans>
            </p>

            <p className="usa-alert__text margin-top-2">
              <Trans i18nKey="healthResources.forMoreInfoGetCoveredNJ">
                placeholder_child_0
                <a
                  href="https://www.nj.gov/getcoverednj/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="usa-link usa-link--external blue-override"
                >
                  placeholder_child_1
                </a>
                placeholder_child_2
              </Trans>
            </p>

            <p className="usa-alert__text margin-top-2">
              <Trans i18nKey={"healthResources.moreHealthResources"}>
                placeholder_child_0
                <a
                  href="https://www.nj.gov/labor/myunemployment/help/resources-support/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="usa-link usa-link--external blue-override"
                >
                  placeholder_child_1
                </a>
                placeholder_child_2
              </Trans>
            </p>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Login;
