import { FormEvent, useState } from "react";
import { logEvent } from "../shared/analytics";
import { ENDPOINTS, SSO_TIMEOUT_ERROR } from "../shared/constants";
import { DataFetchStatus, UserData } from "../shared/types";
import SelfServiceError from "./SelfServiceError";
import { getHeaders } from "../shared/functions/getHeaders";
import { useTranslation } from "react-i18next";

interface Props {
  userData: UserData;
}

function RequestOverpayment({ userData }: Props) {
  const { t } = useTranslation();

  const [fetchStatus, setFetchStatus] = useState(DataFetchStatus.Idle);
  const fetchStatusIsSuccessOrLoading =
    fetchStatus === DataFetchStatus.Success ||
    fetchStatus === DataFetchStatus.Loading;

  const initialEmailState = userData.email;
  const [email, setEmail] = useState(initialEmailState);

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setFetchStatus(DataFetchStatus.Loading);

    const formData = new FormData(e.target as HTMLFormElement);
    const overpaymentData = Object.fromEntries(formData.entries());

    const endpoint = ENDPOINTS.overpayment;
    const headers = getHeaders(userData);

    fetch(endpoint, {
      method: "POST",
      headers: { ...headers },
      body: JSON.stringify({
        email: overpaymentData.email,
      }),
    })
      .then((response) => {
        if (response.status === 403) {
          throw new Error(SSO_TIMEOUT_ERROR);
        }

        return response.json();
      })
      .then(({ status }) => {
        switch (status) {
          case "Success":
            setFetchStatus(DataFetchStatus.Success);
            logEvent("Request Overpayment success");
            break;
          default:
            setFetchStatus(DataFetchStatus.ErrorUnknown);
            logEvent("Request Overpayment error", "Unknown");
            break;
        }
      })
      .catch((error) => {
        if (error.message === SSO_TIMEOUT_ERROR) {
          setFetchStatus(DataFetchStatus.ErrorSessionTimeout);
          logEvent("Request Overpayment error", "SSO session timeout");
        } else {
          setFetchStatus(DataFetchStatus.ErrorUnknown);
          logEvent("Request Overpayment error", "Unknown");
        }
      });

    return false;
  }

  return (
    <article>
      <div className="margin-bottom-2 font-sans-xs display-block mobile-lg:display-none">
        {t("selfService.requestWaiver.subtitle")}
      </div>

      <form
        className="usa-form maxw-none"
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <fieldset className="usa-fieldset">
          <legend className="text-bold">
            {t("selfService.requestWaiver.pleaseConfirm")}
          </legend>
          <div className="maxw-mobile-lg">
            {fetchStatus === DataFetchStatus.Success && (
              <div className="usa-alert usa-alert--success usa-alert--slim">
                <div className="usa-alert__body">
                  <p className="usa-alert__text">
                    {t("selfService.requestWaiver.success")}
                  </p>
                </div>
              </div>
            )}

            {(fetchStatus === DataFetchStatus.ErrorSessionTimeout ||
              fetchStatus === DataFetchStatus.ErrorUnknown) && (
              <div className="margin-top-2">
                <SelfServiceError fetchStatus={fetchStatus} />
              </div>
            )}

            <label className="usa-label" htmlFor="overpaymentEmail">
              {t("status.email")}
            </label>
            <span className="usa-hint" id="overpaymentEmailHint">
              {t("selfService.requestWaiver.weWillSend")}
            </span>
            <input
              className="usa-input margin-bottom-2 mobile-lg:margin-bottom-0"
              id="overpaymentEmail"
              name="email"
              required
              type="email"
              aria-describedby="overpaymentEmailHint"
              disabled={fetchStatusIsSuccessOrLoading}
              defaultValue={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <input
              type="submit"
              className="usa-button usa-button--primary"
              value={t("selfService.submit")}
              disabled={fetchStatusIsSuccessOrLoading || email.length === 0}
            />
          </div>
        </fieldset>
      </form>
      {fetchStatus === DataFetchStatus.Loading && (
        <div className="loader margin-top-4"></div>
      )}
    </article>
  );
}

export default RequestOverpayment;
