import { AuthorizationHeaders, UserData } from "../types";
import { getCookie, getReducedBrowserCookies } from "./cookieUtils";
import { SESSION_COOKIE_NAME } from "../constants";

export function getHeaders(userData: UserData): AuthorizationHeaders {
  const dolSessionCookie = getCookie(SESSION_COOKIE_NAME);
  const headers = {
    "sso-token": dolSessionCookie != null ? dolSessionCookie : "",
    "browser-cookies": getReducedBrowserCookies(),
  };

  return headers;
}
