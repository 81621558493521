export enum Page {
  Login,
  Status,
  NoClaim,
}

export type UserData = {
  email: string;
  firstName: string | undefined;
  lastName: string | undefined;
};

export interface AuthorizationHeaders {
  "sso-token": string;
  "browser-cookies": string;
}

export interface BasicDataHeaders {
  ssn: string;
  dob: string;
}

export type FetchHeaders = AuthorizationHeaders | BasicDataHeaders;

export interface EAdjudicationResponse {
  fetchStatus: ResponseFetchStatus;
  fetchMessage?: string;
  cases?: Array<EAdjCaseDynamoDB>;
}

export interface IdVerificationResponse {
  fetchStatus: ResponseFetchStatus;
  fetchMessage?: string;
  isVerified?: boolean;
  verifiedDate?: string | null;
}

export enum ResponseFetchStatus {
  Success = "Success",
  Error = "Error",
}

export interface StatusData {
  statusCode?: string;
  status?: string;
  claimStatus?: string;
  claimType?: string;
  dateOfClaim?: string;
  selfServiceIds?: Array<string>;
  taxYearCompensation?: string | undefined;
  taxYearWithheld?: string | undefined;
  email?: string;
  firstName?: string;
  lastName?: string;
  idVerification?: IdVerificationResponse | undefined;
  eAdjudication?: EAdjudicationResponse;
}

export interface EAdjCaseDynamoDB {
  CaseId: string;
  ClaimDate: string;
  CreatedDate: string;
  Email: string;
  IssueCode: string;
  LastModifiedDate: string;
  LinkExpirationDate: string;
  LinkSentDate: string;
  LinkUrl: string;
  Source: string;
  Status: string;
}

export enum DataFetchStatus {
  Idle,
  Success,
  Loading,
  ErrorIncorrectInfo,
  ErrorUnknown,
  ErrorSessionTimeout,
}

export enum SsoLoginStatus {
  Pending,
  NoCookie,
  Error,
  Success,
  AccountHasNoClaim,
  FeatureFlagDisabled,
}
