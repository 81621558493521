const SSO_DOMAIN = process.env.REACT_APP_SSO_DOMAIN ?? "";

const BASE_LOGIN_URL = `https://${SSO_DOMAIN}/sso/XUI/?#login/&realm=ui`;
const BASE_CREATE_ACCOUNT_URL = `https://${SSO_DOMAIN}/sso/XUI/?realm=ui#register/`;
const REDIRECT_PARAM = process.env.REACT_APP_REDIRECT_PARAM ?? "";

// URLs
export const LOGIN_URL = `${BASE_LOGIN_URL}&goto=${REDIRECT_PARAM}`;
export const NJ_UNEMPLOYMENT_URL = `https://unemployment.nj.gov`;
export const CREATE_ACCOUNT_URL = `${BASE_CREATE_ACCOUNT_URL}&goto=${REDIRECT_PARAM}`;
export const LOGOUT_URL = `https://${SSO_DOMAIN}/sso/UI/Logout`;
export const IDME_URL = process.env.REACT_APP_IDME_URL ?? "";

export const SESSION_COOKIE_NAME =
  process.env.REACT_APP_SESSION_COOKIE_NAME ?? "";
export const IDME_CLIENT_ID = process.env.REACT_APP_IDME_CLIENT_ID ?? "";
export const IDME_REDIRECT_URI = process.env.REACT_APP_IDME_REDIRECT_URI ?? "";
export const IDME_RESPONSE_TYPE =
  process.env.REACT_APP_IDME_RESPONSE_TYPE ?? "";
export const IDME_SCOPE = process.env.REACT_APP_IDME_SCOPE ?? "";
export const IDME_OPEN_PAGE = process.env.REACT_APP_IDME_OPEN_PAGE ?? "";
export const VERIFY_ROUTE = process.env.REACT_APP_VERIFY_ROUTE ?? "";
export const APPEALS_ROUTE = process.env.REACT_APP_APPEALS_ROUTE ?? "";
export const APPEAL_DETAILS_ROUTE = APPEALS_ROUTE + "/:numericalAppealNumber";
const ROOT_ENDPOINT = process.env.REACT_APP_ROOT_ENDPONT ?? "";
const SSO = "sso";
const APPEALS = "appeals";
const CLAIM_STATUS = "claim/status";
const PIN_RESET = "pin/reset";
const OVERPAYMENT_REQUEST = "overpayment/request";
const REQUEST_1099G = "tax/request1099G";
const GET_IDME_RESPONSE = "idme/verifications";

export const SELF_SERVICE_OPTIONS = {
  REQUEST_OVERPAYMENT: "cdeIssuesMulti9",
  RESET_PIN: "cdeIssuesMulti1",
  REQUEST_1099G: "cdeIssuesMulti5",
  VIEW_1099G: "cdeIssuesMulti7",
};

export const SELF_SERVICE_SUBHEADINGS = {
  RESET_PIN: "You will need your PIN to certify each week.",
  REQUEST_1099G:
    "You will need this at the end of the year when filing your taxes.",
  REQUEST_OVERPAYMENT:
    "This form is a formal request to be forgiven for an overpayment.",
};

export const FEATURE_FLAG_SHOW_I18N_BUTTON =
  process.env.REACT_APP_FEATURE_FLAG_SHOW_I18N_BUTTON === "true";

export const FEATURE_FLAG_CREATE_APPEALS_ROUTE =
  process.env.REACT_APP_FEATURE_FLAG_CREATE_APPEALS_ROUTE === "true";

export const FEATURE_FLAG_SHOW_APPEALS_FILTERS =
  process.env.REACT_APP_FEATURE_FLAG_SHOW_APPEALS_FILTERS === "true";

export const FEATURE_FLAG_APPEALS_SORTING =
  process.env.REACT_APP_FEATURE_FLAG_APPEALS_SORTING === "true";

export const ENDPOINTS: { [endpoint: string]: string } = {
  appeals: `${ROOT_ENDPOINT}/${APPEALS}`,
  status: `${ROOT_ENDPOINT}/${SSO}/${CLAIM_STATUS}`,
  pin: `${ROOT_ENDPOINT}/${SSO}/${PIN_RESET}`,
  overpayment: `${ROOT_ENDPOINT}/${SSO}/${OVERPAYMENT_REQUEST}`,
  request1099g: `${ROOT_ENDPOINT}/${SSO}/${REQUEST_1099G}`,
  getIdmeResponse: `${ROOT_ENDPOINT}/${GET_IDME_RESPONSE}`,
};

export const SSO_TIMEOUT_ERROR = "SSO Timeout";
export const SSO_AUTHORIZER_ERROR = "SSO authorizer failed";
export const IDME_AUTHENTICATION_ERROR = "ID.me authentication failed";
export const IDME_STORE_ERROR = "ID.me store api failed";
